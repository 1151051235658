<template>
  <div class="home-reason">
    <UiHeading :h="'2'" :type="'E2'" :text="'ご利用の流れ'" />
    <div class="cover">
    <UiFlowBox :type="'A'" :barNo="'1'" :barText="'電話・メール・InstagramのDMからご予約'" :answer="'初回来店の場合、スタッフからヒアリングの上、ご利用するマシンのご提案をさせていただきます。'"/>
    <UiFlowBox :type="'A'"  :barNo="'2'" :barText="'予約日時にご来店'" :answer="'初回来店の場合、会員登録とご利用に関するご説明をさせていただきます。ご利用に必要なアイテムはすべて料金に含まれていますので、手ぶらでご来店していただくことができます。'"/>
    <UiFlowBox :type="'A'"  :barNo="'3'" :barText="'お会計'" :answer="'ご利用料金のお支払いをお願いします。クレジットカード・交通系・クイックペイで決済ができます。'"/>
    <UiFlowBox :type="'A'"  :barNo="'4'" :barText="'お着替え'" :answer="'鍵付きロッカーを完備しています。着替え部屋がございますので、お着替えをしていただきます。'"/>
    <UiFlowBox :type="'A'"  :barNo="'5'" :barText="'マシンのご利用'" :answer="'日焼けマシンで日焼けしていただきます。'"/>
    <UiFlowBox :type="'A'"  :barNo="'6'" :barText="'シャワー・着替え'" :answer="'シャワーでお体を流し、お着替えをします。'"/>
</div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiFlowBox from "@/components/Ui/FlowBox.vue";
export default {
  name: "HomeReason",
  components: {
    UiHeading,
    UiFlowBox,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  padding: 50px 0 0;
}
</style>