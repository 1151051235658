<template>
  <div class="home-welcome">
    <!-- <div class="space-70">
      <div class="space-130"> -->
    <div class="wrap-pala">
      <UiHeading :h="'1'" :type="'E2'" :text="'日焼けサロンなら<br class=sp-only>HotDogへ'" />
      <div class="infomation">
        <div class="left">
          <p>
            名古屋栄のテレビ塔からすぐにあるHotDogは、名古屋で20年の実績がある老舗の日焼けサロンです。日焼けに効果的なマシンとバツグンな立地、深夜まで営業していて、手ぶらでも来店できる通いやすさから多くの人に選ばれています。
          </p>
          <UiTable :tableList="TableList" class="table" />
        </div>
        <img src="@/assets/hotdog-top-chizu.png" alt="地図"/>
      </div>
    </div>
    <div class="img-cover">
      <img src="@/assets/hotdog-top-sra001.jpg" alt="店内1"/>
      <img src="@/assets/hotdog-top-sra002.jpg" alt="店内2"/>
      <img src="@/assets/hotdog-top-sra003.jpg" alt="店内3"/>
      <img src="@/assets/hotdog-top-sra004.jpg" alt="店内4"/>
      <img src="@/assets/hotdog-top-sra005.jpg" alt="店内5"/>
      <img src="@/assets/hotdog-top-sra006.jpg" alt="店内6"/>
    </div>
    <!-- <div class="slider-cover">
      <UiSliderBox
        :boxes="boxes"
        :swiperOption="swiperOption"
        :height="100"
        :width="98"
        :button="false"
        class="slider"
      />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiTable from "@/components/Ui/Table.vue";
// import UiSliderBox from "../Ui/SliderBox.vue";
export default {
  name: "HomeWelcome",
  components: {
    UiHeading,
    UiTable,
    // UiSliderBox,
  },
  props: {},
  data() {
    return {
      TableList: [
        {
          id: 1,
          th: "店舗名",
          td: "日焼けサロンHotDog",
        },
        {
          id: 2,
          th: "住所",
          td: "愛知県名古屋市東区東桜1-10-36 第44オーシャンビル 6階",
        },
        {
          id: 3,
          th: "電話番号",
          td: "052-962-9626",
        },
        {
          id: 4,
          th: "営業時間・定休日",
          td: "11時～深夜2時 年中無休",
        },
      ],
      boxes: [
        {
          id: 1,
          imgpath: "hotdog-top-sra001.jpg",
          name: "店内1",
        },
        {
          id: 2,
          imgpath: "hotdog-top-sra001.jpg",
          name: "店内1",
        },
        {
          id: 3,
          imgpath: "hotdog-top-sra001.jpg",
          name: "店内1",
        },
        {
          id: 4,
          imgpath: "hotdog-top-sra001.jpg",
          name: "店内1",
        },
        {
          id: 5,
          imgpath: "hotdog-top-sra001.jpg",
          name: "店内1",
        },
        {
          id: 6,
          imgpath: "hotdog-top-sra001.jpg",
          name: "店内1",
        },
      ],
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 50,
        speed: 500,
        effect: "slide",
        loop: true,
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        //ナビゲーション設定
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1200: {
          slidesPerView: 2,
        },
        600: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.infomation {
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  color: var(--main);
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    display: block;
    padding-bottom: 20px;

  }

  .left {
    width: 60%;
     @media screen and (max-width: 767px){
      width: 100%;
     }

    p {
      padding-bottom: 10%;
      
    }
  }
  img {
    width: 34%;
    @media screen and (max-width: 767px){
      width: 100%;
      margin-top: 50px;
    }
  }
}
.img-cover{
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
  img{
    width: 15%;
    @media screen and (max-width: 767px) {
      width: 31%;
    }
  }
  @media screen and (max-width: 767px){
    img:nth-child(n + 4){
      margin-top: 20px;
    }
  }

}
// .slider {
//   margin: 50px auto;
//   width: 90%;
//   @media screen and (max-width: 767px) {
//     width: 95%;
//   }
// }
// ::v-deep.VueCarousel-dot-container {
//   width: 50px;
//   display: flex !important;
//   justify-content: space-between;
//   padding-top: 20px !important;

//   @media screen and (max-width: 767px) {
//     width: 100px;
//   }
// }
// ::v-deep.VueCarousel-slide .slider-inner {
//   width: 16%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: #fff;
//   border: 2px solid #fff;
//   font-size: 30px;
//   border-radius: 10px;
// }
// ::v-deep.VueCarousel-inner{
// width: 100px!important;
// }
// ::v-deep.slide-img {
//   width: 90%;
// }

</style>