import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                main: '#ec6f00',
                sub: '#231815',
                sub2: '#5f5754'
            },
        },
    },
    options: {
        customProperties: true
    }

});
