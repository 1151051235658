<template>
  <div class="ui-flow-box">
    <div
      :class="{
        typeA: type === 'A',
        typeB: type === 'B',
      }"
    >
      <div class="outer">
        <div class="bar-box">
          <div class="bar-no">
            <p>{{ barNo }}</p>
          </div>
          <div class="bar-text">
            <p>{{ barText }}</p>
          </div>
        </div>
        <div class="answer">
          <p v-html="answer"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiDetailButton from "@/components/Ui/DetailButton.vue";
export default {
  name: "UiBar3",
  components: {
    // UiDetailButton,
  },
  props: {
    barText: String,
    barNo: String,
    answer: String,
    type: String,
    // good: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.typeA,.typeB{
  .outer {
    padding-bottom: 40px;
    .bar-box {
      display: flex;
      .bar-no {
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          color: white;
          font-size: 40px;
        }
      }
      .bar-text {
        width: calc(100% - 50px);
        display: flex;
        align-items: center;
        p {
          color: white;
          font-size: 25px;
          font-weight: bold;
          padding-left: 30px;
          @media screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 1.2em;
            padding-left: 10px;
          }
        }
      }
    }
    .answer {
      margin-left: 68px;
      padding-top: 15px;
      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
      p {
        padding-left: 30px;
        @media screen and (max-width: 767px){
          padding: 0 10px;
        }
      }
    }
  }
}

.typeA {
  .outer {
    .bar-box {
      .bar-no {
        background-color: var(--main);
      }
      .bar-text {
        background-color: var(--sub);
      }
    }
  }
}
.typeB {
    .outer {
    .bar-box {
      .bar-no {
        background-color: var(--sub);
        }
      .bar-text {
        background-color: var(--main);
      }
    }
  }
}
</style>