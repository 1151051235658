<template>
  <div class="home-users">
    <div class="wrap-pala">
      <UiHeading :h="'2'" :type="'E2'" :text="'こんな人が<br class=sp-only>利用しています'" />

      <div class="cover">
        <div class="man"><p>スポーツ選手</p></div>
        <div class="man"><p>ボディビルダー</p></div>
        <div class="man"><p>夜のお仕事の方</p></div>
        <div class="man"><p>トレーナー</p></div>
        <div class="man"><p>ミュージシャン</p></div>
        <div class="man" style="text-align: center;"><p>ギャル系ファッションの方</p></div>
        <div class="man"><p>格闘家</p></div>
        <div class="man"><p>モデル・俳優</p></div>
        <div class="man">
          <p>体の引き締め効果を<br />求める方、などなど</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "HomeUsers",
  components: {
    UiHeading,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 50px 0 100px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .man {
    width: 32%;
    height: 100px;
    background-color: rgba($color: white, $alpha: 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%;
    padding: 0 20px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    p {
      color: var(--sub);
      font-size: 20px;
    }
  }
}
</style>