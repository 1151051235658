<template>
  <div class="top-button" ontouchstart="">
    <div
      class="to-top-button"
      :class="{ 'over-baseline': scrollY >= 500 }"
      v-scroll-to="{
        el: '#app',
        container: 'body',
        duration: 750,
        easing: 'ease',
        offset: 0,
      }"
    >
      <div class="top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="60px"
          height="55px"
          viewBox="0 0 38 38"
          version="1.1"
        >
          <g id="surface1">
            <rect
              x="0"
              y="0"
              width="38"
              height="38"
              style="fill: var(--main); fill-opacity: 1; stroke: none"
            />
            <path
              style="
                stroke: none;
                fill-rule: nonzero;
                fill: rgb(100%, 100%, 100%);
                fill-opacity: 1;
              "
              d="M 28.789062 25.269531 L 19 13.976562 L 9.210938 25.269531 L 8.34375 24.519531 L 19 12.234375 L 29.65625 24.519531 Z M 28.789062 25.269531 "
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiToTopButton",
  props: {
    path: String,
  },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      //スクロールすると実行される
      if (this.scrollTimer === null) {
        //初期値はnull
        this.scrollTimer = setTimeout(
          //ここでnullじゃなくてsetTimeoutになる
          function () {
            //実行するセット
            this.scrollY = window.scrollY; //ウィンドウトップからの画面距離
            clearTimeout(this.scrollTimer); //scrollTimerをリセット
            this.scrollTimer = null; //初期値に戻す
          }.bind(this), //第一引数
          200 //第二引数(ミリ秒後)←0.2秒後にはじまるっていう意味
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.to-top-button {
  display: none;
  position: fixed;
  width: 60px;
  height: auto;
  z-index: 999;
  right: 2%;
  bottom: 6%;
  cursor: pointer;
  opacity: 0.7;
  // border: 2px white solid;

  @media screen and (max-width: 767px) {
    width: 45px;
    opacity: 0.8;
    bottom: 9%;
  }

  &:hover {
    opacity: 1;
  }
}

.top {
  // background-color: (--main);
  background-color: var(--main);
  // background-blend-mode: darken;
  // color: var(--main);
  //  filter: invert(70%) sepia(81%) saturate(580%) hue-rotate(300deg) brightness(106%) contrast(110%);
}
.top {
}

.over-baseline {
  display: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>