<template>
  <div class="home-reason">
    <UiHeading :h="'2'" :type="'E2'" :text="'HotDogが選ばれる理由'" />

    <div class="cover">
      <div class="og">
        <div class="top">
          <h3>好立地＋深夜営業で<br />通いやすい</h3>
        </div>
        <p class="text">
          HotDogは名古屋市栄のテレビ塔からすぐの場所にあり、アクセスの良さが自慢です。また、営業日は深夜2時まで営業しています。手ぶらで通えるようにアイテムをすべて完備しているなど、通いやすい日焼けサロンです。
        </p>
      </div>
      <div class="bl">
        <div class="top">
          <h3>
            名古屋栄で20年、<br class="sp-only" />9,000名以上の<br />ご利用実績
          </h3>
        </div>
        <p class="text">
          名古屋栄でこれまで20年、9,000人以上の方にご利用をいただいています。プロのスポーツ選手からモデル、ミュージシャンなど、いろいろな業界の人のご利用をいただき、また、ご満足をいただき、継続してご来店をしていただいております。
        </p>
      </div>
      <div class="og">
        <div class="top">
          <h3>手ぶらで<br />来店できる</h3>
        </div>
        <p class="text">
          HotDogは料金に「日焼け用オイル、クールダウンオイル、保湿液、シャワー、タオル、ドライヤー」が含まれていて、また、鍵付きのロッカーと着替え部屋もあります。そのため、手ぶらでもご来店していただくことができます。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "HomeReason",
  components: {
    UiHeading,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  display: flex;
  justify-content: space-between;
  padding: 50px 0 200px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .og,
  .bl {
    width: 30%;
    padding: 20px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .top {
      font-weight: bold;
      height: 190px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        h3 {
          font-size: 24px;
          height: 130px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .og {
    background-color: var(--sub);
    &:nth-of-type(3) {
      @media screen and (max-width: 767px) {
        margin-top: 20px;
      }
    }
  }
  .bl {
    background-color: var(--main);
    color: white;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }
}
</style>