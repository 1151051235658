<template>
  <div class="home-machine-body">
    <div class="machine-cover">
      <h2>{{ title }}</h2>
      <div class="machine">
        <div class="left">
          <img :src="require(`@/assets/${src}`)" :alt="{ title }" />
          <div class="left-bottom">
            <p v-html="des" class="des"></p>
            <!-- <p class="wat">{{ wattage }}W</p> -->
          </div>
          <p class="note">{{ note }}</p>
 
          
      </div>

        <UiTable2 :tableList="tableList" class="table"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTable2 from "@/components/Ui/Table2.vue";
export default {
  name: "HomeMachinePrice",
  components: {
    UiTable2,
  },
  props: {
    title: String,
    src: String,
    des: String,
    wattage: String,
    // note:String,
    note: {
      type: String,
      default: "",
    },
    tableList: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-machine-body{
  padding: 100px 0;
  border-bottom: var(--sub) solid 1px;
  h2{
    padding-bottom: 30px;
  }
  .machine-cover{
    .machine{
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        display: block;
      }
      .left{
        width: 48%;
        @media screen and (max-width: 767px){
          width: 100%;
        }
        img{
          max-width: 100%;
        }
        .left-bottom{
          display: flex;
          justify-content: space-between;
          p{
            color: white;
          }
          .wat{
            background-color: var(--sub);
            padding: 0 20px;
            color: black;
            height: 38px;
          }
        }
      }
      .table{
        width: 48%;
        @media screen and (max-width: 767px){
          width: 100%;
          margin-top: 30px;
        }
      }
    .note{
      color: white;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
}}
</style>