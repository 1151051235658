var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-button",attrs:{"ontouchstart":""}},[_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
      el: '#app',
      container: 'body',
      duration: 750,
      easing: 'ease',
      offset: 0,
    }),expression:"{\n      el: '#app',\n      container: 'body',\n      duration: 750,\n      easing: 'ease',\n      offset: 0,\n    }"}],staticClass:"to-top-button",class:{ 'over-baseline': _vm.scrollY >= 500 }},[_c('div',{staticClass:"top"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","width":"60px","height":"55px","viewBox":"0 0 38 38","version":"1.1"}},[_c('g',{attrs:{"id":"surface1"}},[_c('rect',{staticStyle:{"fill":"var(--main)","fill-opacity":"1","stroke":"none"},attrs:{"x":"0","y":"0","width":"38","height":"38"}}),_c('path',{staticStyle:{"stroke":"none","fill-rule":"nonzero","fill":"rgb(100%, 100%, 100%)","fill-opacity":"1"},attrs:{"d":"M 28.789062 25.269531 L 19 13.976562 L 9.210938 25.269531 L 8.34375 24.519531 L 19 12.234375 L 29.65625 24.519531 Z M 28.789062 25.269531 "}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }