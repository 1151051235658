<template>
  <div class="home">
    <div class="top">
      <img
        alt="hotdogトップ"
        src="@/assets/hotdog-top-image001.jpg"
        class="pc-only"
      />
      <img
        alt="hotdogトップ"
        src="@/assets/hotdog-top-image-001sp.jpg"
        class="sp-only"
      />
    </div>

    <v-parallax src="@/assets/hotdog-top-001-2.jpg" :height="height">
          <HomeWelcome  id="shop"/>
    </v-parallax>

    <div class="space-top-70" style="background-color: var(--main)">
      <HomeMachinePrice class="space-top-130" id="price"/>
    </div>

    <div class="space-top-70 wrap">
      <HomeReason class="space-top-130" id="reason"/>
    </div>

        <v-parallax src="@/assets/hotdog-top-002.jpg" :height="height2">
          <HomeUsers />
    </v-parallax>

    <div class="space-top-70 wrap">
      <HomeFlow class="space-top-130" id="flow"/>
    </div>

    <div class="space-top-70 wrap">
      <HomeQa class="space-top-130" id="qa"/>
    </div>

    <div class="space-top-70 wrap">
      <HomeReserve class="space-top-130" id="reservation"/>
    </div>



  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeWelcome from "@/components/Home/Welcome.vue";
import HomeMachinePrice from "@/components/Home/MachinePrice.vue";
import HomeReason from "@/components/Home/Reason.vue";
import HomeUsers from "@/components/Home/Users.vue";
import HomeFlow from "@/components/Home/Flow.vue";
import HomeQa from "@/components/Home/Qa.vue";
import HomeReserve from "@/components/Home/Reserve.vue";
export default {
  name: "Home",
  components: {
    HomeWelcome,
    HomeMachinePrice,
    HomeReason,
    HomeUsers,
    HomeFlow,
    HomeQa,
    HomeReserve,
  },
  props: {},
  data() {
    return {
    height: "",
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {
    if (window.matchMedia("(min-width:768px)").matches) {
      this.height = "1150";
      this.height2 = "800";
    } else if (window.matchMedia("(max-width: 767px)").matches) {
      this.height = "1700";
      this.height2 = "1500";
    } else {
      //no action.
    }
  },
};
</script>
<style lang="scss" scoped>
.top{
  img{
    max-width: 100%;
    vertical-align:top;
  }
}

// ::v-deep.v-parallax__image-container {
//   max-width: 100%;
//   // @media screen and (max-width: 767px) {
//   //   width: auto;
//   //   height: 600px!important;
//   //   img{
//   //     object-fit: cover;
//   //   }
//     // }
//     }


</style>
